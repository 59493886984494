import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.css']
})
export class BottombarComponent implements OnInit {

  sw = 0;
  constructor() { }

  ngOnInit() {
    this.sw = window.innerWidth;
  }
  show(){
    document.getElementById('ac').style.display="block";
  }
  normal(){
    document.getElementById('ac').style.display="none";
  }

}
