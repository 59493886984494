import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-annual',
  templateUrl: './annual.component.html',
  styleUrls: ['./annual.component.css']
})
export class AnnualComponent implements OnInit {

  mdata:any;
  constructor() { }

  ngOnInit() {
  
  this.mdata = [
   
        {imaddr:"assets/img/annual/2023/1",iminfo:""},
        {imaddr:"assets/img/annual/2023/2",iminfo:""},
        {imaddr:"assets/img/annual/2023/3",iminfo:""},
        {imaddr:"assets/img/annual/2023/4",iminfo:""},
        {imaddr:"assets/img/annual/2023/5",iminfo:""},
        {imaddr:"assets/img/annual/2023/6",iminfo:""},
        {imaddr:"assets/img/annual/2023/7",iminfo:""},
        {imaddr:"assets/img/annual/2023/8",iminfo:""},
        {imaddr:"assets/img/annual/2023/9",iminfo:""},
        {imaddr:"assets/img/annual/2023/10",iminfo:""},
        {imaddr:"assets/img/annual/2023/11",iminfo:""},
        {imaddr:"assets/img/annual/2023/12",iminfo:""},
        {imaddr:"assets/img/annual/2023/14",iminfo:""},
        {imaddr:"assets/img/annual/2023/15",iminfo:""},
        {imaddr:"assets/img/annual/2023/16",iminfo:""},
        {imaddr:"assets/img/annual/2023/17",iminfo:""},
        {imaddr:"assets/img/annual/2023/18",iminfo:""},
        {imaddr:"assets/img/annual/2023/19",iminfo:""},
        {imaddr:"assets/img/annual/2023/20",iminfo:""},
        {imaddr:"assets/img/annual/2023/21",iminfo:""},
        {imaddr:"assets/img/annual/2023/22",iminfo:""},
        {imaddr:"assets/img/annual/2023/24",iminfo:""},
        {imaddr:"assets/img/annual/2023/25",iminfo:""},
        {imaddr:"assets/img/annual/2023/26",iminfo:""},
        {imaddr:"assets/img/annual/2023/27",iminfo:""},
        {imaddr:"assets/img/annual/2023/28",iminfo:""},
        {imaddr:"assets/img/annual/2023/29",iminfo:""},
        {imaddr:"assets/img/annual/2023/30",iminfo:""},
        {imaddr:"assets/img/annual/2023/31",iminfo:""},
        {imaddr:"assets/img/annual/2023/32",iminfo:""},
        {imaddr:"assets/img/annual/2023/34",iminfo:""},
        {imaddr:"assets/img/annual/2023/35",iminfo:""},
        {imaddr:"assets/img/annual/2023/36",iminfo:""},
        {imaddr:"assets/img/annual/2023/37",iminfo:""},
        {imaddr:"assets/img/annual/2023/38",iminfo:""},
        {imaddr:"assets/img/annual/2023/39",iminfo:""},
        {imaddr:"assets/img/annual/2023/40",iminfo:""},
        {imaddr:"assets/img/annual/2023/41",iminfo:""},
        {imaddr:"assets/img/annual/2023/42",iminfo:""},
        {imaddr:"assets/img/annual/2023/44",iminfo:""},
        {imaddr:"assets/img/annual/2023/45",iminfo:""},
        {imaddr:"assets/img/annual/2023/46",iminfo:""},
        {imaddr:"assets/img/annual/2023/47",iminfo:""},
        {imaddr:"assets/img/annual/2023/48",iminfo:""},
        {imaddr:"assets/img/annual/2023/49",iminfo:""},
        {imaddr:"assets/img/annual/2023/50",iminfo:""},
        {imaddr:"assets/img/annual/2023/51",iminfo:""},
        {imaddr:"assets/img/annual/2023/52",iminfo:""},
        {imaddr:"assets/img/annual/2023/54",iminfo:""},
        {imaddr:"assets/img/annual/2023/55",iminfo:""},
        {imaddr:"assets/img/annual/2023/56",iminfo:""},
        {imaddr:"assets/img/annual/2023/57",iminfo:""},
        {imaddr:"assets/img/annual/2023/58",iminfo:""},
        {imaddr:"assets/img/annual/2023/59",iminfo:""},
        {imaddr:"assets/img/annual/2023/60",iminfo:""},
        {imaddr:"assets/img/annual/2023/61",iminfo:""},
        {imaddr:"assets/img/annual/2023/62",iminfo:""},
        {imaddr:"assets/img/annual/2023/63",iminfo:""},
        {imaddr:"assets/img/annual/2023/64",iminfo:""},
        {imaddr:"assets/img/annual/2023/65",iminfo:""},
        {imaddr:"assets/img/annual/2023/66",iminfo:""},
        {imaddr:"assets/img/annual/2023/67",iminfo:""},
        {imaddr:"assets/img/annual/2023/68",iminfo:""},
        {imaddr:"assets/img/annual/2023/69",iminfo:""},
        {imaddr:"assets/img/annual/2023/70",iminfo:""},
        {imaddr:"assets/img/annual/2023/71",iminfo:""},
        {imaddr:"assets/img/annual/2023/72",iminfo:""},
        {imaddr:"assets/img/annual/2023/73",iminfo:""},
        {imaddr:"assets/img/annual/2023/74",iminfo:""},
    
        {imaddr:"assets/img/md/2023/1",iminfo:""},
        {imaddr:"assets/img/md/2023/2",iminfo:""},
        {imaddr:"assets/img/md/2023/3",iminfo:""},
        {imaddr:"assets/img/md/2023/4",iminfo:""},
        {imaddr:"assets/img/md/2023/5",iminfo:""},
        {imaddr:"assets/img/md/2023/6",iminfo:""},
        {imaddr:"assets/img/md/2023/7",iminfo:""},
        {imaddr:"assets/img/md/2023/8",iminfo:""},
        {imaddr:"assets/img/md/2023/9",iminfo:""},
        {imaddr:"assets/img/md/2023/10",iminfo:""},
        {imaddr:"assets/img/md/2023/11", iminfo:""},
        {imaddr:"assets/img/md/2023/12",iminfo:""},
        {imaddr:"assets/img/md/2023/13",iminfo:""},
        {imaddr:"assets/img/md/2023/14",iminfo:""},
        {imaddr:"assets/img/md/2023/15",iminfo:""},
        {imaddr:"assets/img/md/2023/16",iminfo:""},
        {imaddr:"assets/img/md/2023/17",iminfo:""},
        {imaddr:"assets/img/md/2023/18",iminfo:""},
        {imaddr:"assets/img/md/2023/19",iminfo:""},
        {imaddr:"assets/img/md/2023/20",iminfo:""},
        {imaddr:"assets/img/md/2023/21", iminfo:""},
        {imaddr:"assets/img/md/2023/22", iminfo:""},
   
        {imaddr:"assets/img/graduate/2023/1",iminfo:""},
        {imaddr:"assets/img/graduate/2023/2",iminfo:""},
        {imaddr:"assets/img/graduate/2023/3",iminfo:""},
        {imaddr:"assets/img/graduate/2023/4",iminfo:""},
        {imaddr:"assets/img/graduate/2023/5",iminfo:""},
        {imaddr:"assets/img/graduate/2023/6",iminfo:""},
        {imaddr:"assets/img/graduate/2023/7",iminfo:""},
        {imaddr:"assets/img/graduate/2023/8",iminfo:""},
        {imaddr:"assets/img/graduate/2023/9",iminfo:""},
        {imaddr:"assets/img/graduate/2023/10",iminfo:""},
        {imaddr:"assets/img/graduate/2023/11",iminfo:""},
        {imaddr:"assets/img/graduate/2023/12",iminfo:""},
        {imaddr:"assets/img/graduate/2023/13",iminfo:""},
        {imaddr:"assets/img/graduate/2023/14",iminfo:""},
        {imaddr:"assets/img/graduate/2023/15",iminfo:""},
        {imaddr:"assets/img/graduate/2023/16",iminfo:""},
 
        {imaddr:"assets/img/rep/74/1", iminfo:""},
        {imaddr:"assets/img/rep/74/2", iminfo:""},
        {imaddr:"assets/img/rep/74/8", iminfo:""},
        {imaddr:"assets/img/rep/74/3", iminfo:""},
        {imaddr:"assets/img/rep/74/4", iminfo:"<h5>T.N.K Ramesh</h5><br><h6>Program Manager<br>AXA XL-Global Insurance Company<br>USA</h6>"},
        {imaddr:"assets/img/rep/74/5", iminfo:"<h5>K.L Baskaran</h5><br><h6>Lograj Enterprises<br>Non Woven Bags Manufacturing<br>Madurai</h6>"},
        {imaddr:"assets/img/rep/74/6", iminfo:""},
        {imaddr:"assets/img/rep/74/7", iminfo:""},
        {imaddr:"assets/img/rep/74/8", iminfo:""},
        {imaddr:"assets/img/rep/74/9", iminfo:""},
        {imaddr:"assets/img/rep/74/10", iminfo:""},
        {imaddr:"assets/img/rep/74/11", iminfo:""},
        {imaddr:"assets/img/rep/74/12", iminfo:""},
        {imaddr:"assets/img/rep/74/12", iminfo:""},
        {imaddr:"assets/img/rep/74/13", iminfo:""},
        {imaddr:"assets/img/rep/74/14", iminfo:""},
        {imaddr:"assets/img/rep/74/15", iminfo:""},
        {imaddr:"assets/img/rep/74/16", iminfo:""},
        {imaddr:"assets/img/rep/74/17", iminfo:""},
        {imaddr:"assets/img/rep/74/18", iminfo:""},
        {imaddr:"assets/img/rep/74/20", iminfo:""},
        {imaddr:"assets/img/rep/74/21", iminfo:""},
        {imaddr:"assets/img/rep/74/22", iminfo:""},
        {imaddr:"assets/img/rep/74/24", iminfo:""},
        {imaddr:"assets/img/rep/74/25", iminfo:""},
        {imaddr:"assets/img/rep/74/26", iminfo:""},
        {imaddr:"assets/img/rep/74/27", iminfo:""},
        {imaddr:"assets/img/rep/74/28", iminfo:""},
   
        {imaddr:"assets/img/sci/2022/1",  iminfo:""},
        {imaddr:"assets/img/sci/2022/2",iminfo:""},
        {imaddr:"assets/img/sci/2022/3",iminfo:""},
        {imaddr:"assets/img/sci/2022/4",iminfo:""},
        {imaddr:"assets/img/sci/2022/5",iminfo:""},
        {imaddr:"assets/img/sci/2022/6",iminfo:""},
        {imaddr:"assets/img/sci/2022/7",iminfo:""},
        {imaddr:"assets/img/sci/2022/8",iminfo:""},
        {imaddr:"assets/img/sci/2022/9",iminfo:""},
        {imaddr:"assets/img/sci/2022/10",iminfo:""},
        {imaddr:"assets/img/sci/2022/11",iminfo:""},
        {imaddr:"assets/img/sci/2022/12",iminfo:""},
        {imaddr:"assets/img/sci/2022/13",iminfo:""},
        {imaddr:"assets/img/sci/2022/14",iminfo:""},
        {imaddr:"assets/img/sci/2022/15",iminfo:""},
        {imaddr:"assets/img/sci/2022/16",iminfo:""},
        {imaddr:"assets/img/sci/2022/17",iminfo:""},
        {imaddr:"assets/img/sci/2022/18",iminfo:""},
        {imaddr:"assets/img/sci/2022/19",iminfo:""},
        {imaddr:"assets/img/sci/2022/20",iminfo:""},
        {imaddr:"assets/img/sci/2022/20",iminfo:""},
        {imaddr:"assets/img/sci/2022/21",iminfo:""},
        {imaddr:"assets/img/sci/2022/22",iminfo:""},
        {imaddr:"assets/img/sci/2022/24",iminfo:""},
        {imaddr:"assets/img/sci/2022/25",iminfo:""},
        {imaddr:"assets/img/sci/2022/26",iminfo:""},
        {imaddr:"assets/img/sci/2022/27",iminfo:""},
        {imaddr:"assets/img/sci/2022/28",iminfo:""},
        {imaddr:"assets/img/sci/2022/29",iminfo:""},
        {imaddr:"assets/img/sci/2022/30",iminfo:""},
        {imaddr:"assets/img/sci/2022/31",iminfo:""},
        {imaddr:"assets/img/sci/2022/32",iminfo:""},
        {imaddr:"assets/img/sci/2022/34",iminfo:""},
        {imaddr:"assets/img/sci/2022/35",iminfo:""},
    
        {imaddr:"assets/img/sports/sports2022/1",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/2",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/3",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/4",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/5",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/6",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/7",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/8",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/9",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/10",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/11",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/12",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/13",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/14",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/15",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/16",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/17",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/01",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/18",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/19",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/20",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/21",iminfo:""},
        {imaddr:"assets/img/sports/sports2022/22",iminfo:""},
 
     
        {imaddr:"assets/img/ind/2022/1",iminfo:""},
        {imaddr:"assets/img/ind/2022/2",iminfo:""},
        {imaddr:"assets/img/ind/2022/3",iminfo:""},
        {imaddr:"assets/img/ind/2022/4",iminfo:""},
        {imaddr:"assets/img/ind/2022/5",iminfo:""},
        {imaddr:"assets/img/ind/2022/6",iminfo:""},
        {imaddr:"assets/img/ind/2022/7",iminfo:""},
        {imaddr:"assets/img/ind/2022/8",iminfo:""},
        {imaddr:"assets/img/ind/2022/9",iminfo:""},
        {imaddr:"assets/img/ind/2022/10",iminfo:""},
        {imaddr:"assets/img/ind/2022/11",iminfo:""},
        {imaddr:"assets/img/ind/2022/12",iminfo:""},
        {imaddr:"assets/img/ind/2022/13",iminfo:""},
        {imaddr:"assets/img/ind/2022/14",iminfo:""},
        {imaddr:"assets/img/ind/2022/15",iminfo:""},
        {imaddr:"assets/img/ind/2022/16",iminfo:""},
        {imaddr:"assets/img/ind/2022/17",iminfo:""},
        {imaddr:"assets/img/ind/2022/01",iminfo:""},
        {imaddr:"assets/img/ind/2022/18",iminfo:""},
        {imaddr:"assets/img/ind/2022/19",iminfo:""},
        {imaddr:"assets/img/ind/2022/20",iminfo:""},
        {imaddr:"assets/img/ind/2022/21",iminfo:""},
        {imaddr:"assets/img/ind/2022/22",iminfo:""},
        {imaddr:"assets/img/ind/2022/23",iminfo:""},
        {imaddr:"assets/img/ind/2022/24",iminfo:""},
        {imaddr:"assets/img/ind/2022/25",iminfo:""},
        {imaddr:"assets/img/ind/2022/26",iminfo:""},
        {imaddr:"assets/img/ind/2022/27",iminfo:""},
        {imaddr:"assets/img/ind/2022/28",iminfo:""},
        {imaddr:"assets/img/ind/2022/29",iminfo:""},
        {imaddr:"assets/img/ind/2022/30",iminfo:""},
        {imaddr:"assets/img/ind/2022/31",iminfo:""},
        {imaddr:"assets/img/ind/2022/32",iminfo:""},
        {imaddr:"assets/img/ind/2022/33",iminfo:""},
        
    // {
    //   evntid: "ind",
    //   evntname: "Deepavali Celebration",
    //   evntquote: "",
    //   evntimage: [
    //     "assets/img/diwali/1.JPG",
    //     "assets/img/diwali/2.JPG",
    //     "assets/img/diwali/3.JPG",
    //     "assets/img/diwali/4.JPG",
    //     "assets/img/diwali/5.JPG",
    //     "assets/img/diwali/6.JPG",
    //     "assets/img/diwali/7.JPG",
    //     "assets/img/diwali/8.JPG",
    //     "assets/img/diwali/9.JPG",
    //     "assets/img/diwali/10.JPG",
    //     "assets/img/diwali/11.JPG",
    //     "assets/img/diwali/12.JPG",
    //     "assets/img/diwali/14.JPG",
    //     "assets/img/diwali/15.JPG",
    //     "assets/img/diwali/16.JPG",

    //   ],
    // },

    // {
    //   evntid: "cov",
    //   evntname: "Vaccination Camp",
    //   evntquote: "Conducted Vaccination Camp",
    //   evntimage: [
    //     "assets/img/cov/1.JPG",
    //     "assets/img/cov/2.JPG",
    //     "assets/img/cov/3.JPG",
    //     "assets/img/cov/4.JPG",
    //     "assets/img/cov/5.JPG",
    //     "assets/img/cov/6.JPG",
    //     "assets/img/cov/7.JPG",
    //     "assets/img/cov/8.JPG",
    //     "assets/img/cov/9.JPG",
    //     "assets/img/cov/10.JPG",
    //     "assets/img/cov/11.JPG",
    //     "assets/img/cov/12.JPG",
    //     "assets/img/cov/14.JPG",
    //     "assets/img/cov/15.JPG",
    //     "assets/img/cov/16.JPG",
    //     "assets/img/cov/17.JPG",
    //     "assets/img/cov/18.JPG",
    //     "assets/img/cov/19.JPG",
    //     "assets/img/cov/20.JPG",
    //     "assets/img/cov/21.JPG",
    //   ],
    // },
    // {
    //   evntid: "spe",
    //   evntname: "Special Event",
    //   evntquote: "Worship for the Welfare of the World",
    //   evntimage: [
    //     "assets/img/special/wor/1.jpg",
    //     "assets/img/special/wor/2.jpg",
    //     "assets/img/special/wor/3.jpg",
    //     "assets/img/special/wor/4.jpg",
    //     "assets/img/special/wor/5.jpg",
    //     "assets/img/special/wor/6.jpg",
    //   ],
    // },

   


  ]

  
}
}