import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BottombarComponent } from 'src/app/base/bottombar/bottombar.component';
import { HomeComponent } from 'src/app/home/home.component';
import { GalleryComponent } from 'src/app/gallery/gallery.component';
import { AluminiComponent } from 'src/app/alumini/alumini.component';
import { AboutComponent } from 'src/app/about/about.component';
import { TopbarComponent } from 'src/app/base/topbar/topbar.component';
import { InfrasComponent } from 'src/app/infras/infras.component';
import { TalentsComponent } from 'src/app/talents/talents.component';
import { NewadmComponent } from 'src/app/adm/newadm/newadm.component';
import { ExiadmComponent } from 'src/app/adm/exiadm/exiadm.component';
import { AdmsignComponent } from 'src/app/adm/admsign/admsign.component';
import { AdmComponent } from 'src/app/adm/adm.component';
import { AnnualComponent } from './annual/annual.component';


const routes: Routes = [
  {
    path: "", component: HomeComponent
  },
  {
    path: "infras", component: InfrasComponent
  },
  {
    path: "gallery", component: GalleryComponent
  },
  {
    path: "alumini", component: AluminiComponent
  },
  {
    path: "about", component: AboutComponent
  },
  {
    path: "nadm", component: NewadmComponent
  },
  {
    path: "eadm", component: ExiadmComponent
  },
  {
    path: "tal", component: TalentsComponent
  },
  {
    path: "sign", component: AdmsignComponent
  },
  {
    path: "admret", component: AdmComponent
  },
  {
    path: "annual", component: AnnualComponent
  },
  { path: 'gall-list', loadChildren: () => import('./gall/gall.module').then(m => m.GallModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration:'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
