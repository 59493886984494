import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommunService } from 'src/app/commun.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-adm',
  templateUrl: './adm.component.html',
  styleUrls: ['./adm.component.css']
})
export class AdmComponent implements OnInit {

  dstr: any[];
  bd: any;
  rr: any;
  fgret: FormGroup;
  g: boolean;
  constructor(private serving: CommunService, private http: HttpClient, private fb: FormBuilder, private rout: Router) {
    this.fgret = fb.group({
      dstr: [null, Validators.required],
    });
  }

  fgrcheck: any = {
    dstr: { required: "Please Mention Date" },
  };

  ngOnInit() {
    if (localStorage.getItem("iid") == null) {
      (async () => {
        // await delay(5000);
        // this.rout.navigate(["/"])
      })();
    }
    this.alret("all");
  }

  ag(vale: string) {
    if (vale == 'today') {
      this.fgret.patchValue({ "dstr": formatDate(Date.now(), "yyyy/MM/dd", "en") })
      this.alret('specific');
      setTimeout(() => {        
        this.fgret.reset();
      }, 1000);
      // console.log("printed");

    } else if (vale == 'specific') {
      this.ret();
      setTimeout(() => {
        this.g = true;
      }, 200);
      setTimeout(() => {
        this.g = false;
      }, 500);
    } else {
      this.alret("allot");
    }
  }

  ret() {
    if (this.fgret.status == "INVALID" || this.fgret.status == "PENDING") {
      var d = this.serving.checking(this.fgret, this.fgrcheck);
      alert(d);
    }
    else {
      this.alret("specific");
    }

    // 
  }
  logout() {
    this.serving.logcheckout(localStorage.getItem('iid')).subscribe(
      (res: any) => { });
    localStorage.clear()
    this.rout.navigate(["/admsign"]);

  }
  sign(valu: string, valu1: string) {

    if (valu == 'alloted') {
      console.log(valu, valu1)
      this.serving.reqcheckout(valu1).subscribe(
        (res: any) => { });
      localStorage.clear()
      this.rout.navigate(["/admret"]);
    } else {
      this.serving.reqremove(valu1).subscribe(
        (res: any) => { });
      localStorage.clear()
      this.rout.navigate(["/admret"]);
    }

  }
  alret(sendr: string) {
    this.serving.aret(this.fgret.value, sendr).subscribe(
      (res: any) => {
        // console.log("res", res.br);
        res = res.trimLeft(); res = res.trimRight();
        if (!res) {
          this.rr = [];
        }
        else {
          // var d = JSON.parse(res).br;
          this.rr = JSON.parse(res).br;
        }
      });
  }

}
