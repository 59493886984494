import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infras',
  templateUrl: './infras.component.html',
  styleUrls: ['./infras.component.css']
})
export class InfrasComponent implements OnInit {

  inf: any;
  constructor() { }

  ngOnInit() {
    this.inf = []
    for (let i = 1; i < 41; i++) {
      this.inf.push({ imaddr: "assets/img/infra/infras/" + i + ".jpg" })
    }

  }

}
