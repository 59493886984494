import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  iyear: any[];
  iyear1: any[];
  constructor() { }

  ngOnInit() {
    this.iyear = [
      {
        lyear: "1989", linfo: "\" Idea of starting a school at Nilaiyur Kaithari Nagar conceived. \"",
        ryear: "1990", rinfo: "\" New school started in a rental building under the management of \“Sourashtra Kalvi Valarchi Kazhagam\”. Student Strength 129. \""
      },
      {
        lyear: "1992", linfo: "\" 1.25 acres of land purchased and new building \“Vishnu Block\” admeasuring 2400 sq. ft was constructed. Started functioning in the own building from October 1992. Student Strength 248. \"",
        ryear: "1996", rinfo: "\" Got government recognition for the standards 1st to 5th. \""
      },
      {
        lyear: "1999-\n2001", linfo: "\" Got government recognition for the standards 6th  7th and 8th respectively. \"",
        ryear: "2000", rinfo: "\" TN Governments mid day meal scheme started. \""
      },
      {
        lyear: "2001", linfo: "\" TN government started providing Free Books to all students. \"",
        ryear: "2002", rinfo: "\" First batch of 8th standard students appeared for government exams. \""
      },
     
    ]
    this.iyear1 = [
      {
        lyear: "2005", linfo: "\" New building called “Shiva Block” was constructed. \"",
        ryear: "2009", rinfo: "\" New school started in a rental building under the management of \“Sourashtra Kalvi Valarchi Kazhagam\”. Student Strength 129. \""
      },
      {
        lyear: "2010", linfo: "\" RO Plant Installed. From May 2010    RO water is provided to the students.  Computer Lab facility started.  For   All the students of Tamil Medium,   Uniforms are provided by the Donors. \"",
        ryear: "2010", rinfo: "\" Sourashtra Kalvi Valarchi Kalagam’s   20th Anniversary celebrated. \""
      },
      {
        lyear: "2015", linfo: "\" Silver Jubilee Building Constructed with a good spacious auditorium. Sourashtra Kalvi Valarchi Kalagam and the Middle School celebrated its SILVER JUBILEE. \"",
        ryear: "2017", rinfo: "\" A permanent stage for conducting meetings and cultural activities etc., was constructed. \""
      },
      {
        lyear: "2018", linfo: "\" SMART CLASS  /   CCTV installation   /   Biometric Attendance   are now part of  our school. \"",
        ryear: "2019", rinfo: "\" We create that, what you are currently looking that's nkkr.in  \""
      },
     
    ]
  }

}
