import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-talents',
  templateUrl: './talents.component.html',
  styleUrls: ['./talents.component.css']
})
export class TalentsComponent implements OnInit {

  constructor(private http: HttpClient) { }
  drawing: any;
  dt: string[];
  i: any;
  ngOnInit() {
    this.drawing = [];
    this.dt = [];
    this.http.get("/assets/img/talentexpo/oladdress.txt", { responseType: 'text' as 'json' }).subscribe((res: any) => {
      this.dt = res.split("^^");
      console.log(this.dt.length, this.dt);
      for (this.i = 0; this.i < this.dt.length; this.i++) {
        (this.dt[this.i]).trimLeft(); (this.dt[this.i]).trimRight();
        this.drawing.push(JSON.parse(this.dt[this.i]));
      }
    });
    // let finame = new Blob(["/assets/address.txt"], { type: "text" });
    // let fr: FileReader = new FileReader();
    // let self: this;
    // fr.onloadend = function (x) {
    //   // self.ddata = fr.result;
    // }
    // // this.ddata =
    // fr.readAsText(finame);
    // console.log(this.ddata);
  }

}

