import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router, Event } from '@angular/router';
import { $ } from 'protractor';
import { Validators } from '@angular/forms';
import { CommunService } from 'src/app/commun.service';


@Component({
  selector: 'app-alumini',
  templateUrl: './alumini.component.html',
  styleUrls: ['./alumini.component.css']
})
export class AluminiComponent implements OnInit {

  fgreg: FormGroup;
  fglog: FormGroup;
  // ageval: number[];
  rshow: string;
  // lshow: FormControl;
  imgurl: string;

  constructor(private cover: CommunService, private fb: FormBuilder, private rout: Router) {

    this.fgreg = fb.group({
      rname: [null, Validators.required],
      raddr: [null, Validators.required],
      rphno: [null, Validators.compose([Validators.required, Validators.minLength(10),
      Validators.maxLength(10)])],
      rmail: [null, Validators.compose([Validators.required, Validators.email])],
      rcs: [null, Validators.required],
      rposition: [null, Validators.required],
      rpoy: [null, Validators.compose([Validators.required, Validators.minLength(4),Validators.maxLength(4),])],

    })

  }


  fgrcheck: any = {
    rname: { required: "Please Enter your Name" },
    raddr: { required: "Please Enter your Address" },
    rphno: {
      required: "Please Enter your Phone no", minlength: "Please Enter Valid Phone no",
      maxlength: "Please Enter Valid Phone no"
    },
    rmail: { required: "Please Enter Email Id", email: "Please Enter Valid Mail ID" },
    rcs: { required: "Please Enter Classes Studied ex:1-5 or 1,3,4,5" },
    rposition: { required: "Please Enter Your Current Positon" },
    rpoy: { required: "Please Enter Passedout year",minlength: "Please Enter Valid Year",
    maxlength: "Please Enter Valid Year" }
  }

  ngOnInit() {
    // this.ageval = [];
    // for (let i = 14, j = 0; i < 101; i++ , j++) {
    //   this.ageval[j] = i;
    // }
    
  }

  preurl(event: any): void {
    if (event.target.files && event.target.files[0]) {
      var imp = new FileReader();
      imp.readAsDataURL(event.target.files[0])
      imp.onload = (event: any) => {
        this.imgurl = event.target.result;
      }
    }
  }

  // sign() {
  //   var d;
  //   if (this.fglog.status == "INVALID" || this.fglog.status == "PENDING") {
  //     d = this.cover.checking(this.fglog, this.fglcheck);
  //     this.lshow = d;
  //   }
  //   else {
  //     var dd,pa; dd = "goods@out.com"; pa = "enterinto";
  //     if (this.fglog.controls.lemail.value == dd && this.fglog.controls.lpass.value == pa) {
  //       this.rout.navigate(["/enterinto"]);
  //     }
  //     d = "Login id :goods@out.com Pass:enterinto";
  //     this.lshow = d;
  //   }
  // }
  pascpas(valu: string, valu1: string) {

    if (valu.length <= valu1.length) {
      if (valu1 != valu || valu1.length > valu.length) {
        var d;
        d = "Mismatch Password & Confirm Password";
        this.rshow = d;
      }
    }

  }
  newuser() {
    var d;
    if (this.fgreg.status == "INVALID" || this.fgreg.status == "PENDING") {
      d = this.cover.checking(this.fgreg, this.fgrcheck);
      alert(d);
    }
    else {
      this.cover.addu(this.fgreg.value).subscribe(
        (res: any) => {
          console.log("res", res);
          res = res.trimLeft(); res = res.trimRight();
          if (res == 'inserted') {
            alert("Congrats, Successfully Registered");
          }
          else if (res == 'Already exist User') {
            alert(res);
          }
          else {
            alert("Some Data Missing");
          }
        }
      )
      this.fgreg.reset();
    }
  }
  // send(ss: any) {
  //   console.log(ss);
  //   if (ss > 1990 && ss < 2018) {
  //       this.fgreg.value.rpoy.value=ss;
  //   }
  //   else
  //     this.fgreg.value.rpoy.value=1990;

  // }

}
