import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TopbarComponent } from './base/topbar/topbar.component';
import { BottombarComponent } from './base/bottombar/bottombar.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AluminiComponent } from './alumini/alumini.component';
import { InfrasComponent } from './infras/infras.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { TalentsComponent } from './talents/talents.component';
import { NewadmComponent } from './adm/newadm/newadm.component';
import { ExiadmComponent } from './adm/exiadm/exiadm.component';
import { RouterModule } from '@angular/router';
import { AdmComponent } from './adm/adm.component';
import { AdmsignComponent } from './adm/admsign/admsign.component';
import { AnnualComponent } from './annual/annual.component';

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    BottombarComponent,
    HomeComponent,
    AboutComponent,
    GalleryComponent,
    AluminiComponent,
    InfrasComponent,
    TalentsComponent,
    NewadmComponent,
    ExiadmComponent,
    AdmComponent,
    AdmsignComponent,
    AnnualComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,ReactiveFormsModule,HttpClientModule,RouterModule
  ],
  providers: [{
    provide:LocationStrategy,
    useClass:HashLocationStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
